<template>
  <div class="backDoor"  :style="'background-image: url(https://www.swetlow.de/vika/advent/'+no+'.jpg)'">
    <div class="door" @click="toggle" :class="[open ? 'doorOpen' : '']">
      {{no}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Door',
  props: {
    no: String
  },
  methods: {
    toggle() {
      this.open = !this.open
    }
  },
  data() {
    return {
      open: false,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.backDoor
{
  background-color: #333;
  position:relative;
  width:300px;
  height:250px;

  margin: 0 auto;
  margin-top:50px;
  border-radius: 8px;
  background-size: cover;
  background-position: center center;

}

.door
{
  background-color: brown;
  position:absolute;
  top:0px;
  left:0px;
  width:300px;
  height:250px;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  border-radius: 8px;

  transform-origin: left;
  /*Speed of the Door animation*/
  transition: all 0.5s ease-in-out;
}

.doorOpen
{
  /*prespectiv creates the door open effect*/
  transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-105deg);
}

</style>
